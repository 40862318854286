import { FunctionField, ReferenceField } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"

export function TaxField(props) {
  const [organization_id] = useOrganizationId()
  if (!organization_id) return null
  return (
    <ReferenceField
      {...props}
      label="Tax Rate"
      reference="hh2/tax_rates"
      queryOptions={{ meta: { organization_id } }}
    >
      <FunctionField
        source="taxPercent"
        render={({ name, taxPercent }) => `${name} (${taxPercent}%)`}
      />
    </ReferenceField>
  )
}
