import {
  ReferenceInput,
  SimpleForm,
  TextInput,
  useCreate,
  useDataProvider,
  useRedirect,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { AmountTypeInput } from "../Components/AmountTypeInput"

export function InvoiceItemFromConsumables({ consumableRecords, projectId }) {
  const [organization_id] = useOrganizationId()
  const redirect = useRedirect()
  const [create] = useCreate()
  const dataProvider = useDataProvider()
  const handleCreate = (record) => {
    Promise.all(
      consumableRecords.map(async (consumableRecord) => {
        const { tool_id } = consumableRecord
        const { data: tool } = await dataProvider.getOne("tools", {
          id: tool_id,
        })
        const { quantity, value } = tool
        const {
          invoice_id,
          description: customDescription,
          tax_group_id,
          amount_type,
          tax_status_id,
        } = record
        const unit_price = value?.substring(1) ?? 0
        const line_total = unit_price * quantity
        const description = `${tool.display_name}\n${customDescription ?? ""}`
        const data = {
          invoice_id,
          organization_id,
          tool_id,
          quantity,
          description,
          unit_price,
          line_total,
          tax_group_id,
          amount_type,
          tax_status_id,
        }
        return create("invoice_items", { data })
      })
    ).then(() => {
      redirect("show", "invoices", record.invoice_id)
    })
  }
  return (
    <SimpleForm
      onSubmit={handleCreate}
      defaultValues={{ amount_type: "Material" }}
    >
      <ReferenceInput
        meta
        source="invoice_id"
        reference="invoices"
        filter={{
          organization_id,
          "deleted_at@is": null,
        }}
      />
      <TextInput source="description" multiline rows={3} />
      {/* <TaxGroupInput source="tax_group_id" /> */}
      <AmountTypeInput source="amount_type" />
      {/* <TaxStatusInput source="tax_status_id" /> */}
    </SimpleForm>
  )
}
