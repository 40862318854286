import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from "react-admin"
import { MoneyField } from "../Components/MoneyField"

export function InvoiceItemDatagrid(props) {
  return (
    <Datagrid rowClick="edit" {...props}>
      <ReferenceField source="tool_id" reference="tools" />
      <TextField source="description" />
      <FunctionField
        source="quantity"
        textAlign="right"
        render={({ quantity, unit }) => `${quantity} ${unit ?? ""}`}
      />
      <MoneyField source="unit_price" textAlign="right" />
      <MoneyField source="line_total" textAlign="right" />
      {/* <DateField source="created_at" />
    <DateField source="updated_at" /> */}
    </Datagrid>
  )
}
export function InvoiceItemList() {
  return (
    <List exporter={false}>
      <InvoiceItemDatagrid />
    </List>
  )
}
