import {
  Button,
  useCreate,
  useGetList,
  useNotify,
  useRecordContext,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

export function SageExportInvoiceButton(props) {
  const [organization_id] = useOrganizationId()
  const [create, { isLoading: isSaving }] = useCreate("hh2/documents")
  const invoice = useRecordContext()
  const { data: invoiceItems, isLoading } = useGetList("invoice_items", {
    filter: { invoice_id: invoice?.invoice_id },
  })
  const notify = useNotify()

  if (!invoice) {
    return null
  }
  const distributions = invoiceItems
    ?.map((x) => ({ job_id: invoice.hh2_job_id, ...x }))
    .map(hh2DistributionFromInvoiceItem)
  const data = {
    documentTypeId: "24BAD714-2DD9-4BF1-A451-BFB4E49162FB",
    snapshot: {
      distributions,
      header: {
        accountingDate: invoice.invoice_date,
        amount: invoice.total_amount,
        code: invoice.invoice_number,
        invoiceDate: invoice.invoice_date,
        customerId: invoice.hh2_customer_id,
        revenueAccountId: invoice.hh2_account_id,
        paymentDate: invoice.due_date,
      },
    },
    externalUrl:
      "https://dagrs.berkeley.edu/sites/default/files/2020-01/sample.pdf",
  }
  return (
    <Button
      disabled={isLoading || isSaving}
      {...props}
      label="Export to Sage"
      onClick={() => {
        // eslint-disable-next-line no-alert
        create(
          "hh2/documents",
          {
            meta: {
              organization_id,
            },
            data,
          },
          {
            onSuccess: () => {
              notify("Invoice exported to Sage", { type: "info" })
            },
            onError: (error: Error) => {
              notify(`Error: ${error?.message || error}`, { type: "error" })
            },
          }
        )
      }}
    />
  )
}

function hh2DistributionFromInvoiceItem(invoiceItem) {
  const {
    line_total,
    description,
    // tax_group_id,
    amount_type,
    // tax_status_id,
    quantity,
    unit_price,
    job_id,
  } = invoiceItem
  return {
    amount: line_total,
    units: quantity,
    unitCost: unit_price,
    description, // TODO: Make sure we set the details when creating the invoice item description
    // taxGroupId: tax_group_id,
    // taxStatusId: tax_status_id,
    amountType: amount_type,
    jobId: job_id,
  }
}

// {
//   "Snapshot": {
//       "Header": {
//           "AccountingDate": "2022-05-30", // invoice.updated_at ?
//           "Amount": 123.45, // invoice.total_amount
//           "Code": "INV-OI812",  // QQ: Do we need to add data entry for this?
//           "InvoiceDate": "2022-05-28", // invoice.invoice_date
//           "Name": "Test Invoice", // QQ: What is the name? Does it show anywhere?
//           "CustomerId": "8d1fc505-84e3-4743-bb1a-b21e01546729"
//           // I guess we'll have to pull this off the project? QQ: Does Rush invoice by Project of by Customer?
//       }
//   },
//   "ExternalUrl": "https://dagrs.berkeley.edu/sites/default/files/2020-01/sample.pdf"
//   // https://admin.tooltribe.com/invoices/${invoice.invoice_id}/show
// }

// {
//   "DocumentTypeId": "24BAD714-2DD9-4BF1-A451-BFB4E49162FB",
//   "Snapshot": {
//       "Distributions": [
//           {
//               "Amount": 123.45,
//               "Description": "Test distribution",
//               "TaxGroupId": "b830da46-5089-4479-b016-b21e01546756",
//               "TaxStatusId": "13aad4d6-5074-4401-83d1-b21e015461a2",
//               "AmountType": "Total billed"
//           }
//       ],
//       "Header": {
//           "AccountingDate": "2022-05-30",
//           "Amount": 123.45,
//           "Code": "INV-OI812",
//           "InvoiceDate": "2022-05-28",
//           "PaymentDate": "2022-06-30",
//           "DiscountDate": "2022-07-14",
//           "CustomerId": "8d1fc505-84e3-4743-bb1a-b21e01546729"
//       }
//   },
//   "ExternalUrl": "https://dagrs.berkeley.edu/sites/default/files/2020-01/sample.pdf"
// }
