import { AutocompleteInput, ReferenceInput } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"

export function JobInput(props) {
  const [organization_id] = useOrganizationId()
  if (!organization_id) return null
  return (
    <ReferenceInput
      {...props}
      label="resources.hh2/jobs.name"
      source="hh2_job_id"
      reference="hh2/jobs"
      filter={{ organization_id }}
      queryOptions={{ meta: { organization_id } }}
    >
      <AutocompleteInput label="resources.hh2/jobs.name" optionText="name" />
    </ReferenceInput>
  )
}
