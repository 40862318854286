import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { MoneyField } from "../Components/MoneyField"

export function InvoiceList() {
  const [organization_id] = useOrganizationId()
  return (
    <List filter={{ organization_id, "deleted_at@is": null }} exporter={false}>
      <Datagrid rowClick="show">
        <ReferenceField
          source="hh2_customer_id"
          reference="hh2/customers"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="hh2_job_id"
          reference="hh2/jobs"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="hh2_account_id"
          reference="hh2/accounts"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="invoice_number" />
        <DateField source="invoice_date" />
        <DateField source="due_date" />
        <MoneyField source="total_amount" />
        <TextField source="status" textTransform="capitalize" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
  )
}
