import { usePDF } from "@react-pdf/renderer"
import { useEffect } from "react"
import {
  Button,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  Title,
  TopToolbar,
  useGetList,
  useGetOne,
  useRecordContext,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { TaxField } from "../Components/HH2/TaxField"
import { MoneyField } from "../Components/MoneyField"
import { InvoicePdf } from "./InvoicePdf"
import { SageExportInvoiceButton } from "./SageExportInvoiceButton"

function InvoiceShowActions() {
  return (
    <TopToolbar>
      <EditButton />
      <SageExportInvoiceButton />
      <InvoicePdfButton />
    </TopToolbar>
  )
}

const InvoicePdfButton = () => {
  const invoice = useRecordContext()
  const { data: invoiceItems, isLoading } = useGetList("invoice_items", {
    filter: { invoice_id: invoice?.invoice_id, "deleted_at@is": null },
  })

  const { data: customer } = useGetOne("hh2/customers", {
    id: invoice?.hh2_customer_id,
    meta: { organization_id: invoice?.organization_id },
  })
  const { data: job } = useGetOne("hh2/jobs", {
    id: invoice?.hh2_job_id,
    meta: { organization_id: invoice?.organization_id },
  })
  const { data: taxRate } = useGetOne("hh2/tax_rates", {
    id: invoice?.hh2_tax_rate_id,
    meta: { organization_id: invoice?.organization_id },
  })
  const { data: organization } = useGetOne("organizations", {
    id: invoice?.organization_id,
  })

  const [instance, updateInstance] = usePDF({
    document: InvoicePdf({
      invoice,
      invoiceItems,
      customer,
      job,
      taxRate,
      organization,
    }),
  })

  useEffect(() => {
    if (!invoice || !invoiceItems || !customer || !job || !taxRate) return
    updateInstance(
      InvoicePdf({
        invoice,
        invoiceItems,
        customer,
        job,
        taxRate,
        organization,
      })
    )
  }, [
    invoice,
    invoiceItems,
    updateInstance,
    customer,
    job,
    taxRate,
    organization,
  ])

  if (!invoice || isLoading) {
    return null
  }

  if (instance.loading) return <div>Loading ...</div>

  if (instance.error) return <div>Something went wrong: {instance.error}</div>

  return (
    <Button
      href={instance.url}
      disabled={instance.loading}
      label="Download PDF"
      LinkComponent={(props) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a {...props} download={`invoice-${invoice.invoice_number}.pdf`} />
      )}
    />
  )
}

export function InvoiceShow() {
  const [organization_id] = useOrganizationId()
  if (!organization_id) return null
  return (
    <Show title={<Title> Invoice </Title>} actions={<InvoiceShowActions />}>
      <SimpleShowLayout>
        <ReferenceField
          source="hh2_customer_id"
          reference="hh2/customers"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Job"
          source="hh2_job_id"
          reference="hh2/jobs"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Account"
          source="hh2_account_id"
          reference="hh2/accounts"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="invoice_number" />
        <TextField source="status" textTransform="capitalize" />
        <TaxField label="Tax Rate" source="hh2_tax_rate_id" />
        <DateField source="invoice_date" />
        <DateField source="due_date" />
        <ReferenceManyField
          target="invoice_id"
          source="invoice_id"
          reference="invoice_items"
          filter={{ "deleted_at@is": null, organization_id }}
        >
          <Datagrid rowClick="edit">
            {/* <ReferenceField source="tool_id" reference="tools" /> */}
            <TextField component="pre" source="description" />
            <FunctionField
              source="quantity"
              textAlign="right"
              render={({ quantity, unit }) => `${quantity} ${unit ?? ""}`}
            />
            <MoneyField source="unit_price" textAlign="right" />
            <MoneyField source="line_total" textAlign="right" />
            {/* <DateField source="created_at" />
    <DateField source="updated_at" /> */}
          </Datagrid>
        </ReferenceManyField>
        <MoneyField source="total_amount" />
        {/* <DateField source="created_at" />
        <DateField source="updated_at" /> */}
      </SimpleShowLayout>
    </Show>
  )
}
