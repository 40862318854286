import { ReferenceInput, SimpleForm, TextInput } from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { AmountTypeInput } from "../Components/AmountTypeInput"

export function InvoiceItemForm() {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm values={{ organization_id }}>
      <ReferenceInput
        source="invoice_id"
        reference="invoices"
        filter={{
          organization_id,
          // project_id: projectId, TODO: projectId is not defined
          "deleted_at@is": null,
        }}
      />
      <TextInput source="description" multiline rows={3} />
      {/* <TaxGroupInput source="tax_group_id" /> */}
      <AmountTypeInput source="amount_type" />
      {/* <TaxStatusInput source="tax_status_id" /> */}
    </SimpleForm>
  )
}
