import { ReferenceInput, SelectInput } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"

export function TaxRateInput(props) {
  const [organization_id] = useOrganizationId()
  if (!organization_id) return null
  return (
    <ReferenceInput
      {...props}
      reference="hh2/tax_rates"
      filter={{ organization_id }}
      queryOptions={{ meta: { organization_id } }}
    >
      <SelectInput
        optionText={({ name, taxPercent }) => `${name} (${taxPercent}%)`}
      />
    </ReferenceInput>
  )
}
